import { useAuthStore } from "~/store/auth";

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const { $toast } = useNuxtApp();

  if (process.client) {
    if (!authStore.company?.activated) {
      $toast.error("Votre compte n'est pas encore activé.");
      return navigateTo("/dashboard");
    }
  }
});
